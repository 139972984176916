import React from "react"
import { graphql } from 'gatsby'
import "styles/pages/blog.scss"

// Components
import Layout from "components/Layout"
import SEO from "components/SEO/"
import PostLink from "components/PostLink"

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout pathname="blog">
      <SEO title="Blog" pathname="/blog" />
      <div className="blog container">
        <div className="posts">
          {Posts}
        </div>
      </div>
    </Layout>
  );
}

export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            author
            title
            excerpt
            categories
          }
          fields {
            slug
          }
          html
        }
      }
    }
  }
`